import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import Navbar from './components/Navbar';
import Footer from './components/Footer';

// const LazyPricing = React.lazy(() => import('./components/Pricing'));
const LazyLandingPage = React.lazy(() => import('./components/LandingPage'));
const LazyProjectsOverview = React.lazy(
  () => import('./components/ProjectsOverview')
);
const LazySingleProject = React.lazy(
  () => import('./components/SingleProject')
);
const LazyAboutMe = React.lazy(() => import('./components/AboutMe'));
// const LazyContact = React.lazy(() => import('./components/Contact'));

const AppRouter: React.FC<{}> = () => {
  return (
    <BrowserRouter>
      <Navbar />
      <React.Suspense fallback={<h1>Lazy Loading...</h1>}>
        <Switch>
          <Route exact path='/' component={LazyLandingPage} />
          <Route exact path='/projects' component={LazyProjectsOverview} />
          <Route exact path='/projects/:id' component={LazySingleProject} />
          <Route exact path='/aboutme' component={LazyAboutMe} />
        </Switch>
      </React.Suspense>
      <Footer />
    </BrowserRouter>
  );
};

export default AppRouter;
