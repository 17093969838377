import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

const Navbar: React.FunctionComponent<{}> = () => {
  const [showNavbar, setShowNavbar] = useState(false);
  let history = useHistory();

  const toggledNavbarClassname = showNavbar
    ? 'toggled-navbar'
    : 'toggled-navbar hide';

  const getScrollLocation = () => {
    var portfolioParagraph = document.getElementsByClassName('portfolio-p');
    if (portfolioParagraph.length) {
      return portfolioParagraph[0].getBoundingClientRect().top;
    }
    return 0;
  };

  return (
    <React.Fragment>
      <div className='navbar-desktop'>
        <div className='logo-navbar'>
          <img src='mobile-hero.jpg' alt='' />
          <p>Marc Wittwer</p>
        </div>

        <div className='navbar-links'>
          <p onClick={() => history.push('/')}>Home</p>
          <p
            onClick={() => {
              history.push('/');
              getScrollLocation();
              window.scrollTo({ top: getScrollLocation(), behavior: 'smooth' });
            }}
          >
            Portfolio
          </p>
          <p onClick={() => history.push('/projects')}>Projekte</p>
          <p onClick={() => history.push('/aboutme')}>Über mich</p>
        </div>
      </div>

      <div
        className='navbar-mobile'
        onClick={() => {
          if (showNavbar) {
            setShowNavbar(false);
          } else {
            window.scrollTo({
              top: 0,
              behavior: 'smooth',
            });
          }
        }}
      >
        <div
          className='logo'
          onClick={() => {
            history.push('/');
            setShowNavbar(false);
          }}
        >
          <p className='text-title'>MW</p>
        </div>

        <div className='hamburger' onClick={() => setShowNavbar(!showNavbar)}>
          <div style={{ marginBottom: '4px' }}></div>
          <div></div>
          <div style={{ marginTop: '4px' }}></div>
        </div>
      </div>

      <div className={toggledNavbarClassname}>
        <p
          className='link'
          onClick={() => {
            setShowNavbar(false);
            history.push('/');
          }}
        >
          Home
        </p>
        <p
          className='link'
          onClick={() => {
            setShowNavbar(false);
            history.push('/');
          }}
        >
          Portfolio
        </p>
        <p
          className='link'
          onClick={() => {
            setShowNavbar(false);
            history.push('/projects');
          }}
        >
          Projekte
        </p>

        <p
          className='link'
          onClick={() => {
            setShowNavbar(false);
            history.push('/aboutme');
          }}
        >
          Über mich
        </p>
      </div>
    </React.Fragment>
  );
};

export default Navbar;
