import React from 'react';

const Footer: React.FunctionComponent<{}> = () => {
  return (
    <React.Fragment>
      <div className='footer'>
        <div className='logo-group'>
          <p className='logo'>Marc Wittwer</p>
          <p className='subtitle'>Fotograf</p>
        </div>

        <div className='contact-details'>
          <p>foto@marcwittwer.com</p>
        </div>

        <div className='copyright'>
          <p>Copyright © {new Date().getFullYear()} Marc Wittwer</p>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Footer;
