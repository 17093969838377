import React from 'react';

import './styles/style.css';
import AppRouter from './router';

function App() {
  return <AppRouter />;
}

export default App;
